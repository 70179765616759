'use client'

import HomeBase from '../modules/base'
import { LayoutContext, useLayoutState } from './context'

export default function HomeLayout({
  image,
  mImage,
  alt,
  home = false,
  children
}) {

  const state = useLayoutState()
  return (
    <HomeBase image={image} mImage={mImage} alt={alt} home={home}>
      <LayoutContext.Provider value={state}>
        {children}
      </LayoutContext.Provider>
    </HomeBase>
  )
}