import ABTest from '@component/abTest'
import useHomeSearchContext from '@homeSearch/modules/context'
import classNames from 'classnames'
import { find, first, isArray, isEqual, map } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { RESIDENTIAL_SEARCH_TYPES, isSuburbSearchType } from '../../config/content'
import useHomeSearchFilterContext from '../../context'
import { RESIDENTIAL_FILTER } from '../../config'

export default function SearchType({
  className
}) {

  return (
    <ABTest newComponent={<SearchTypeNew className={className} />}>
      <SearchTypeNormal className={className} />
    </ABTest>
  )
}

function SearchTypeNormal({
  className
}) {

  const { filter } = useHomeSearchFilterContext()
  return (
    <div className={classNames('overflow-y-visible overflow-x-auto', className)}>
      <ul className='flex space-x-8'>
        {
          map(filter.searchTypes, (item) => (
            <TypeItem key={item.label} data={item}></TypeItem>
          ))
        }
      </ul>
    </div>
  )
}

function SearchTypeNew({
  className
}) {

  const { filter } = useHomeSearchFilterContext()
  let { searchTypes } = filter
  if (isEqual(RESIDENTIAL_FILTER.searchTypes, searchTypes)) {
    const labels = ['For Sale', 'Estimates', 'For Rent', 'Sold properties', 'Suburb']
    searchTypes = map(RESIDENTIAL_SEARCH_TYPES, (item, idx) => {
      let label = labels[idx]
      return { ...item, label }
    })
  }
  return (
    <div className={classNames('overflow-y-visible overflow-x-auto', className)}>
      <ul className='flex space-x-8'>
        {
          map(searchTypes, (item) => (
            <TypeItem key={item.label} data={item}></TypeItem>
          ))
        }
      </ul>
    </div>
  )
}

function TypeItem({
  data
}) {
  const { label, Icon, alias } = data
  const { searchType, changeSearchType } = useHomeSearchFilterContext()
  const { suburbs, setSuburbs } = useHomeSearchContext()
  const typeClickHandle = useCallback(() => {
    // 如果选到suburb, 则留下一个suburb
    if (isSuburbSearchType(data)) {
      const d = find(suburbs, item => item.level === 'suburb')
      if (d) {
        setSuburbs(isArray(d) ? [first(d)] : [d])
      } else {
        setSuburbs([])
      }
    }
    changeSearchType(data)
  }, [data, changeSearchType, suburbs, setSuburbs])
  const isActive = useMemo(() => (searchType && searchType.alias === alias), [searchType, alias])

  return (
    <li
      className={classNames(
        'flex-1 flex flex-col items-center justify-center px-14 cursor-pointer rounded-sm border min-w-[96px]',
        Boolean(Icon) ? 'h-96' : 'h-40',
        isActive ? 'bg-primary-light border-primary' : 'bg-neutral-1 border-transparent')}
      onClick={typeClickHandle}
    >
      {
        Icon && (<Icon width={38} height={38} fill={isActive ? 'var(--color-theme)' : undefined}></Icon>)
      }
      <span className={classNames('text-center text-sm leading-14', { 'mt-4': Boolean(Icon) }, isActive ? 'text-primary' : 'text-font')}>{label}</span>
    </li>
  )
}
