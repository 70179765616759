'use client'

import classNames from 'classnames'
import { debounce, filter, findIndex, first, get, isEqual, isFunction, isUndefined, last, map, maxBy, size, split } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { mumFormat, toNumber, toPrice } from '@utils'
import Slider from 'rc-slider'
import '/src/styles/common/rsSlider.scss'
import ABTest from '@component/abTest'

export default function PriceContent({
  value: defaultValue,
  data,
  onChange,
  height
}) {
  // slider最大值
  const count = size(data) - 1
  // price data中最大的一条数据
  const maxItem = maxBy(data, item => item.num)
  // 当前slider的value值:[0-41]
  // 有defaultValue，通过defaultValue换算成slider value
  const [value, setValue] = useState(size(defaultValue) ? valueToIndex(defaultValue, data) : [0, count])
  // 给onChange加上防抖函数
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeHandle = useCallback(debounce(onChange, 300), [onChange])
  // 计算出当前priceFrom, priceTo, priceMin, priceMax
  const [priceFrom, priceTo, priceMin, priceMax] = useMemo(() => {
    const minData = getItemMin(first(data))
    const [calcMaxData, maxData] = getItemMax(last(data))

    const min = getItemMin(get(data, first(value)))
    const [max] = getItemMax(get(data, last(value)))
    return [
      min === minData ? undefined : min,
      max === calcMaxData ? undefined : max,
      minData,
      maxData
    ]
  }, [value, data])
  // 计算出当前选中的items
  const selectedItems = useMemo(() => {
    if (!priceFrom && !priceTo) return []
    return filter(data, (val, idx) => idx >= get(value, 0) && idx <= get(value, 1))
  }, [data, value, priceFrom, priceTo])

  // priceFrom, priceTo发生改变，抛出change事件
  useEffect(() => {
    isFunction(changeHandle) && changeHandle({ priceFrom, priceTo, selectedItems, priceMin, priceMax })
  }, [priceFrom, priceTo, selectedItems, changeHandle, priceMin, priceMax])
  // defalutValue发生变换，修改内部value数据
  useEffect(() => {
    setValue(d => {
      if (isUndefined(defaultValue)) return d
      const newVal = valueToIndex(defaultValue, data)
      if (isEqual(d, newVal)) {
        return d
      } else {
        return newVal
      }
    })
  }, [setValue, defaultValue, data])

  return (
    <div className='px-20'>
      <ABTest newComponent={
        <ContentNew
          value={value}
          setValue={setValue}
          count={count}
          priceFrom={priceFrom}
          priceTo={priceTo}
          priceMin={priceMin}
          priceMax={priceMax}
        />
      }>
        <ContentNormal
          height={height}
          data={data}
          maxItem={maxItem}
          value={value}
          setValue={setValue}
          count={count}
          priceFrom={priceFrom}
          priceTo={priceTo}
          priceMin={priceMin}
          priceMax={priceMax}
        />
      </ABTest>
    </div>
  )
}

function ContentNormal({
  height,
  data,
  maxItem,
  value,
  setValue,
  count,
  priceFrom,
  priceTo,
  priceMin,
  priceMax
}) {
  return (
    <>
      <div className='h-100 flex items-end justify-between gap-x-4 md:h-200' style={{ height }}>
        {
          map(data, (item, idx) => (
            <div className={classNames('rounded-t-sm flex-1 max-w-20', idx >= get(value, 0) && idx <= get(value, 1) ? 'bg-secondary' : 'bg-secondary-light')} style={{ height: `${(item.num / maxItem.num * 100).toFixed(2)}%` }} key={item.label + item.num}></div>
          ))
        }
      </div>
      <Slider
        className='-mt-20'
        range
        min={0}
        max={count}
        value={value}
        onChange={setValue}
      ></Slider>
      <div className='text-xl text-secondary flex justify-between -mx-20'>
        <span>${mumFormat(priceFrom || priceMin, '0,0.[00]')}</span>
        <span>${mumFormat(priceTo || priceMax, '0,0.[00]')}{Boolean(!priceTo && priceMax) && '+'}</span>
      </div>
    </>
  )
}

function ContentNew({
  value,
  setValue,
  count,
  priceFrom,
  priceTo,
  priceMin,
  priceMax
}) {
  return (
    <>
      <Slider
        range
        min={0}
        max={count}
        value={value}
        onChange={setValue}
      ></Slider>
      <div className='text-xl text-font flex justify-between -mx-20'>
        <span>{toPrice(priceFrom || priceMin)}</span>
        <span>{toPrice(priceTo || priceMax)}{Boolean(!priceTo && priceMax) && '+'}</span>
      </div>
    </>
  )
}

function valueToIndex(value, data) {
  if (!size(value)) {
    return [0, size(data) - 1]
  }

  const min = toNumber(get(value, 0))
  const max = toNumber(get(value, 1))
  const minIdx = findIndex(data, item => getItemMin(item) === min)
  const maxIdx = findIndex(data, item => getItemMax(item)[1] === max)
  return [
    minIdx === -1 ? 0 : minIdx,
    (toNumber(last(data).label) === max || maxIdx === -1)
      ? size(data) - 1
      : maxIdx,
  ]
}

function getItemMin(data) {
  return toNumber(first(split(get(data, 'label'), '-')))
}
function getItemMax(data) {
  const label = get(data, 'label', '')
  let res = toNumber(last(split(get(data, 'label'), '-')))
  return [label.includes('-') ? res : res + 1, res]
}