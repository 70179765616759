'use client'

import MaskLoading from '@component/loading/mask'
import useFetch from '@hooks/useFetch'
import { map, size } from 'lodash-es'
import FilterItemNormal from './item/normal'
import { useEffect } from 'react'
import useHomeSearchFilterContext from '../../context'
import { HOUSE_FILTER_ALIAS } from '@config'
import FilterItemPrice from './item/price'
import FilterItemSurrounding from './item/surrounding'
import FilterItemKeywords from './item/keywords'
import classNames from 'classnames'

export default function FilterList({
  params
}) {

  const { setData, filter, searchType, viewData, showAll, toggleShowAll, useShowAll } = useHomeSearchFilterContext()
  const { data, isLoading } = useFetch(params ? { url: searchType.url || filter.url, params } : null, { keepPreviousData: true })
  useEffect(() => {
    if (!data && params) return
    setData(data)
  }, [data, setData, params])

  return params && (
    <div className='relative min-h-[200px]'>
      <div className='space-y-16'>
        {
          map(viewData, (item, idx) => (
            item.alias && (<FilterItem data={item} key={`${item.alias}-${idx}-${searchType.value}`}></FilterItem>)
          ))
        }
      </div>
      {
        useShowAll && size(viewData) > 0 && (
          <div className='text-center mt-20'>
            <button className='button-text-primary text-base' onClick={toggleShowAll}>
              {showAll ? 'Less filters' : 'More filters'}
              <i className={classNames('icon icon-arrow-down ml-4 inline-block align-middle transition-transform', { 'rotate-180': showAll })}></i>
            </button>
          </div>
        )
      }
      <MaskLoading transparent loading={isLoading}></MaskLoading>
    </div>
  )
}

function FilterItem({
  data
}) {

  if (HOUSE_FILTER_ALIAS.price.includes(data.alias)) {
    return <FilterItemPrice data={data}></FilterItemPrice>
  }

  // Parking
  if (data.alias === 'pkn') {
    return (
      <>
        <FilterItemNormal data={data}></FilterItemNormal>
        <FilterItemSurrounding />
      </>
    )
  }

  // Tag
  if (data.alias === 'tua') {
    const d = { ...data, label: 'Keywords and tags' }
    return (
      <FilterItemNormal data={d}>
        <FilterItemKeywords />
      </FilterItemNormal>
    )
  }

  return <FilterItemNormal data={data}></FilterItemNormal>
}