'use client'

import { map } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import useHomeSearchFilterContext from '@homeSearch/modules/filter/context'
import { ItemBase } from '../index'
import PriceContent from './content'

// 价格filter
export default function FilterItemPrice({
  data
}) {

  const { priceFrom, priceTo, setPriceFrom, setPriceTo, setSelect } = useHomeSearchFilterContext()
  const value = useMemo(() => [priceFrom, priceTo], [priceFrom, priceTo])

  const changeHandle = useCallback(({ priceFrom, priceTo, selectedItems }) => {
    setPriceFrom(priceFrom)
    setPriceTo(priceTo)
    setSelect(d => ({ ...d, [data.alias]: map(selectedItems, item => item.value) }))
  }, [setPriceFrom, setPriceTo, setSelect, data.alias])

  return (
    <ItemBase data={data} collapse={false}>
      <PriceContent value={value} data={data.children} onChange={changeHandle}></PriceContent>
    </ItemBase>
  )
}