'use client'

import useHomeSearchFilterContext from '@homeSearch/modules/filter/context'
import { debounce } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'

export default function Keywords() {
  const { description, setDescription } = useHomeSearchFilterContext()
  const [value, setValue] = useState(description)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const change = useCallback(debounce(setDescription, 300), [setDescription])
  const handleChange = useCallback(event => {
    const { value } = event.target
    setValue(value)
    change(value)
  }, [setValue, change])

  useEffect(() => {
    setValue(v => {
      if (v === description) return v
      return description
    })
  }, [description, setValue])

  return (
    <div className='border rounded-sm mb-10 flex pl-16'>
      <input type='text' className='h-48 flex-1 min-w-0' value={value} onChange={handleChange} />
      <button type='button' className='cursor-pointer px-16'>
        <i className='icon icon-search text-neutral-3 !text-xl'></i>
      </button>
    </div>
  )
}