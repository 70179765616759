
import { HOUSE_FILTER_ALIAS, ROUTER_FILTER_ALIAS } from '@config'
import { areaLableToNumber, getSlug, toNumber } from '@utils'
import { each, filter, find, findIndex, first, keys, pull, size, sortBy } from 'lodash-es'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useBoolean, useList } from 'react-use'

export const HomeSearchFilterContext = createContext()
export default function useHomeSearchFilterContext() {
  return useContext(HomeSearchFilterContext)
}

export function useHomeSearchFilterState(searchTypes, defaultSearchType, useShowAll = false) {

  // search type
  const [searchType, setSearchType] = useState(defaultSearchType || first(searchTypes))
  // 当前显示的filter
  const [data, setData] = useState()
  const totals = useMemo(() => data ? parseInt(data.headers['x-total-count']) : 0, [data])
  const selectTotals = useMemo(() => data ? parseInt(data.headers['x-selected-count']) : 0, [data])
  // 是否显示全部filter
  const [showAll, setShowAll] = useBoolean(!useShowAll)
  const toggleShowAll = useCallback(() => setShowAll(), [setShowAll])

  // 选中的price
  const [priceFrom, setPriceFrom] = useState()
  const [priceTo, setPriceTo] = useState()
  // 选中的bedroom
  const [bedroom, bedroomMethod] = useList([])
  // 选中的bathroom
  const [bathroom, bathroomMethod] = useList([])
  // 选中的carspaces
  const [carspaces, carspacesMethod] = useList([])
  // 选中的propertyType
  const [propertyType, propertyTypeMethod] = useList([])
  // 选中的categories
  const [categories, categoriesMethod] = useList([])
  // 选中的landarea
  const [landarea, landareaMethod] = useList([])
  // 选中的floorarea
  const [floorarea, floorareaMethod] = useList([])
  // 填入的keywords
  const [description, setDescription] = useState('')
  // 当前选中的filter
  const [select, setSelect] = useState({})
  // 给houses/filter用的参数
  const filterQuery = useMemo(() => {
    let res = []
    each(select, (val, key) => {
      if (size(val)) {
        res.push([key, val.join(',')].join('_'))
      }
    })
    return res.join('_')
  }, [select])
  // 给url用的
  const routerQuery = useMemo(() => {
    let res = []
    each(select, (val, key) => {
      if (size(val) && !ROUTER_FILTER_ALIAS.includes(key)) {
        res.push([key, val.join(',')].join('_'))
      }
    })
    return res.join('_')
  }, [select])

  // view data
  const viewData = useMemo(() => {
    const alias = [
      // sale
      'prn', 'bern', 'barn', 'ptn', 'tt', 'sm', 'fa', 'la',
      // rent
      'prrn', 'ptrn',
      // tag
      // 'tua'
    ]
    const d = filter(keys(select), key => size(select[key]) > 0)
    alias.push(...d)

    return showAll ? data : filter(data, item => item.alias && alias.includes(item.alias))
  }, [showAll, data, select])

  // 切换选中一个item
  const toggleSelectItem = useCallback(item => {
    const list = select[item.alias] || []
    const value = parseInt(item.value)
    if (find(list, v => (v === value))) {
      pull(list, value)
    } else {
      if (!item.num) return
      list.push(value)
    }
    setSelect(d => ({ ...d, [item.alias]: sortBy(list) }))

    // 更新路由上要使用的参数
    // bedroom
    toggleItemData(HOUSE_FILTER_ALIAS.bedroom, bedroom, bedroomMethod, item)
    // bathroom
    toggleItemData(HOUSE_FILTER_ALIAS.bathroom, bathroom, bathroomMethod, item)
    // carspaces
    toggleItemData(HOUSE_FILTER_ALIAS.carspaces, carspaces, carspacesMethod, item)
    // propertyType
    toggleItemData(HOUSE_FILTER_ALIAS.propertyType, propertyType, propertyTypeMethod, item, true)
    // categories
    toggleItemData(HOUSE_FILTER_ALIAS.categories, categories, categoriesMethod, item, true)
    // landarea
    toggleItemData(HOUSE_FILTER_ALIAS.landarea, landarea, landareaMethod, item, false, true)
    // floorarea
    toggleItemData(HOUSE_FILTER_ALIAS.floorarea, floorarea, floorareaMethod, item, false, true)
  }, [
    select, setSelect,
    bedroom, bedroomMethod,
    bathroom, bathroomMethod,
    carspaces, carspacesMethod,
    propertyType, propertyTypeMethod,
    categories, categoriesMethod,
    landarea, landareaMethod,
    floorarea, floorareaMethod,
  ])
  const clearSelect = useCallback(() => {
    setSelect({})
    setDescription('')
  }, [setSelect, setDescription])
  // change search type
  const changeSearchType = useCallback(type => {
    setSearchType(type)
    setSelect({})
    setPriceFrom()
    setPriceTo()
    setDescription('')
    bedroomMethod.reset()
    bathroomMethod.reset()
    carspacesMethod.reset()
    propertyTypeMethod.reset()
    categoriesMethod.reset()
    landareaMethod.reset()
    floorareaMethod.reset()
  }, [
    setSearchType,
    setSelect,
    setPriceFrom,
    setPriceTo,
    setDescription,
    bedroomMethod,
    bathroomMethod,
    carspacesMethod,
    propertyTypeMethod,
    categoriesMethod,
    landareaMethod,
    floorareaMethod
  ])

  const routerData = {
    routerQuery,
    searchType,
    priceFrom,
    priceTo,
    description,
    bedroom,
    bathroom,
    carspaces,
    propertyType,
    categories,
    landarea,
    floorarea
  }

  return {
    searchType,
    data,
    viewData,
    showAll,
    select,
    filterQuery,
    routerQuery,
    totals,
    selectTotals,
    priceFrom,
    priceTo,
    description,
    setPriceFrom,
    setPriceTo,
    setDescription,
    changeSearchType,
    setData,
    toggleSelectItem,
    setSelect,
    clearSelect,
    toggleShowAll,
    routerData
  }
}


function toggleItemData(alias, data, methods, item, useLabel = false, labelNum = false) {
  const val = useLabel ? getSlug(item.label, item.value) : labelNum ? areaLableToNumber(item.label) : toNumber(item.value)
  if (alias.includes(item.alias)) {
    let idx = findIndex(data, item => item === val)
    if (idx !== -1) {
      methods.removeAt(idx)
    } else {
      methods.push(val)
    }
  }
}
