import { map } from 'lodash-es'
import { ItemBase, ItemBlock } from '../index'
import { HOUSE_FILTER_ALIAS } from '@config'
import classNames from 'classnames'

// 普通的filter
export default function FilterItemNormal({
  data,
  children
}) {

  const isBedroom = HOUSE_FILTER_ALIAS.bedroom.includes(data.alias)
  const isBathroom = HOUSE_FILTER_ALIAS.bathroom.includes(data.alias)
  const isCarspaces = HOUSE_FILTER_ALIAS.carspaces.includes(data.alias)
  return (
    <ItemBase data={data}>
      {children}
      <div className={classNames('grid grid-cols-2 gap-14', { '!grid-cols-5': isBedroom || isBathroom || isCarspaces })}>
        {
          map(data.children, (item, idx) => (
            <ItemBlock key={idx} data={item} outNum={isBedroom || isBathroom || isCarspaces}></ItemBlock>
          ))
        }
      </div>
    </ItemBase>
  )
}