'use client'

import { FilterPopup } from '@component/fullPopup/popup'
import HomeSearchFilterContent, { ClearButton, ConfirmButton } from './content'
import { HomeSearchContext, useHomeSearchState } from '../context'
import { useCallback, useEffect } from 'react'
import { isFunction } from 'lodash-es'
import { HomeSearchFilterContext, useHomeSearchFilterState } from './context'

export default function HomeSearchFilter({
  show = false,
  suburbs,
  searchType,
  surrounding,
  setSuburbs,
  toggleSurrounding,
  onClose,
  onConfirm,
  filter,
  defaultSearchType,
  oneSuburb = false,
  useShowAll = false,
  fetcher,
  placeholder,
  mobilePlaceholder
}) {

  const filterState = useHomeSearchFilterState(filter.searchTypes, defaultSearchType, useShowAll)
  const { searchType: currentSearchType, changeSearchType } = filterState
  const state = useHomeSearchState(oneSuburb, true, currentSearchType)
  useEffect(() => {
    searchType && changeSearchType(searchType)
  }, [searchType, changeSearchType])

  // input向filter popup更新suburbs 和 surrounding
  const { setSuburbs: stateSetSuburbs, toggleSurrounding: stateToggleSurrounding } = state
  useEffect(() => stateSetSuburbs(suburbs), [suburbs, stateSetSuburbs])
  useEffect(() => stateToggleSurrounding(surrounding), [surrounding, stateToggleSurrounding])

  const closeHandle = useCallback(() => {
    setSuburbs(state.suburbs)
    toggleSurrounding(state.surrounding)
    isFunction(onClose) && onClose()
  }, [onClose, state.suburbs, state.surrounding, setSuburbs, toggleSurrounding])


  return (
    <HomeSearchContext.Provider value={state}>
      <HomeSearchFilterContext.Provider value={{ ...filterState, filter, useShowAll }}>
        <FilterPopup
          title='Search by location'
          show={show}
          onBack={closeHandle}
          onClose={closeHandle}
          innerClassName='space-y-16'
          confirmButton={<ConfirmButton onConfirm={onConfirm}></ConfirmButton>}
          cancelButton={<ClearButton></ClearButton>}
        >
          {show && <HomeSearchFilterContent fetcher={fetcher} onConfirm={onConfirm} placeholder={placeholder} mobilePlaceholder={mobilePlaceholder}></HomeSearchFilterContent>}
        </FilterPopup>
      </HomeSearchFilterContext.Provider>
    </HomeSearchContext.Provider>
  )
}