'use client'

import SwitchContent from '@component/form/switch/content'
import useHomeSearchContext from '@homeSearch/modules/context'

export default function Surrounding() {
  const { surrounding, toggleSurrounding } = useHomeSearchContext()

  return (
    <div className='flex items-end' onClick={toggleSurrounding}>
      <div className='flex-1 min-w-0'>
        <div className='text-font font-bold text-base'>Surrounding suburbs</div>
        <div className='text-sm text-font-secondary2'>Broaden your search results to include neighbouring suburbs.</div>
      </div>
      <SwitchContent checked={surrounding} onChange={toggleSurrounding}></SwitchContent>
    </div>
  )
}